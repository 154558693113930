.nav__menu-item a {
    z-index: 10;
}

.nav__menu-item--active {
    color: #5C68CD;
}

.nav__menu-item--active::before {
    content: "";
    background-color: #F2F3FF;
    height: 39px;
    width: 5.75rem;
    border-radius: 500%;
    position: absolute;
    top: 50%;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
}

.nav__menu-item--active::after {
    content: url("../../images/assets/hand-drawn-ellipse.png");
    height: 39px;
    position: absolute;
    top: 50%;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
}


@media only screen and (max-width: 1024px) {
    .nav__menu-item {
        border-bottom: 1px solid #F6F6F6;
    }
}
